/* Fonts */
@font-face {
    font-family: "BeVietnamPro-Black";
    src: url(../src/assets/fonts/BeVietnamPro/BeVietnamPro-Black.ttf);
}

@font-face {
    font-family: "BeVietnamPro-Bold";
    src: url(../src/assets/fonts/BeVietnamPro/BeVietnamPro-Bold.ttf);
}

@font-face {
    font-family: "BeVietnamPro-ExtraBold";
    src: url(../src/assets/fonts/BeVietnamPro/BeVietnamPro-ExtraBold.ttf);
}

@font-face {
    font-family: "BeVietnamPro-ExtraLight";
    src: url(../src/assets/fonts/BeVietnamPro/BeVietnamPro-ExtraLight.ttf);
}

@font-face {
    font-family: "BeVietnamPro-Light";
    src: url(../src/assets/fonts/BeVietnamPro/BeVietnamPro-Light.ttf);
}

@font-face {
    font-family: "BeVietnamPro-Medium";
    src: url(../src/assets/fonts/BeVietnamPro/BeVietnamPro-Medium.ttf);
}

@font-face {
    font-family: "BeVietnamPro-SemiBold";
    src: url(../src/assets/fonts/BeVietnamPro/BeVietnamPro-SemiBold.ttf);
}

@font-face {
    font-family: "BeVietnamPro-Regular";
    src: url(../src/assets/fonts/BeVietnamPro/BeVietnamPro-Regular.ttf);
}

@font-face {
    font-family: "BeVietnamPro-Thin";
    src: url(../src/assets/fonts/BeVietnamPro/BeVietnamPro-Thin.ttf);
}

/* Root Variables */
:root {
    --dns-color-white: #fff;
    --dns-color-black: #000;
    --dns-color-text: #5a6a85;
    --dns-secondcolor-text: #878a99;
    --dns-color-blue: #110f2b;
    --dns-color-text-gray: #797979;
    --dns-color-bg-gray: #eae9e9;
    --dns-color-line-gray: #d1d1dc;
    --dns-color-gray: #d2d2d2;
    --dns-color-red: #d80707;
    --dns-color-red-light: #d807071a;
    --dns-color-blue-light: #110f2b1a;
    --dns-color-orange: #e36414;
    --dns-color-green: #008000;
    --dns-color-green-light: #0080001a;
    --dns-color-yellow: #ffc400;
    --dns-color-seen-chat: #0084c8;
    --dns-color-highlight: #99ff99;
    --dns-hover-bg: #f3f2f3;
    --dns-input-bg: #f9f9f9;
    --dns-card-border: #e9ebec;
    --dns-table-border: #e6e9eb;
    --dns-cal-table-border: #a9a9a9;
    --dns-table-header-bg: #f3f6f9;
}


/* Global Styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-family: "BeVietnamPro-Regular", sans-serif;
    font-size: 100%;
    font-style: inherit;
    font-weight: 400;
}

html {
    min-height: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    color: var(--dns-color-black);
}

body {
    font: 75%/150% "BeVietnamPro-Regular", sans-serif;
    background-color: var(--dns-color-white);
    color: var(--dns-color-black);
    padding: 0 !important;
}

a {
    text-decoration: none;
    color: inherit;
}

ul,
li {
    list-style: none;
}

img {
    /* width: 100%; */
    vertical-align: middle;
    border-style: none;
}

p {
    font-size: 1.2em;
    line-height: 1.5;
    color: var(--dns-color-black);
    font-family: "BeVietnamPro-Regular", sans-serif;
}

table {
    width: 100%;
}

table tbody,
table tr td,
table tfoot,
table,
table td th,
table thead,
table tr {
    border: none;
    vertical-align: middle !important;
    font-size: 12.5px;
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--dns-color-black);
    font-family: "BeVietnamPro-Bold", sans-serif;
    font-weight: 800;
}

h1 {
    font-size: 2.5em;
    line-height: 1.25em;
}

h2 {
    font-size: 1.6667em;
    line-height: 1.25em;
}

h3 {
    font-size: 1.5em;
    line-height: 1.2222em;
}

h4 {
    font-size: 1.3333em;
    line-height: 1.25em;
}

h5 {
    font-size: 1.1666em;
    line-height: 1.1428em;
}

h6 {
    font-size: 1em;
}



input::placeholder {
    font-size: 13.5px;
    color: var(--dns-secondcolor-text) !important;
}

input:focus::placeholder {
    color: transparent !important;
}

/*HTML overflow Hidden CSS :: START*/

.sidebar-minimized .sidebar .nav-link {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.sidebar {
    background-color: var(--dns-color-white) !important;
    border: none;
    border-radius: 0px 10px 10px 0px !important;
    box-shadow: 0px 0px 25px -12px rgb(0 0 0 / 75%);
}

.sidebar .nav-link:hover {
    color: var(--dns-color-red) !important;
    border-right: var(--dns-color-red) 5px solid !important;
    background-color: var(--dns-color-red-light) !important;
}

.sidebar .nav-link:hover .nav-icon {
    color: var(--dns-color-red) !important;
}

.sidebar .nav-title {
    color: #333 !important;
}

.sidebar .sidebar-minimizer {
    background-color: var(--dns-color-white) !important;
    color: var(--dns-color-red) !important;
}

.sidebar-minimized .sidebar .sidebar-minimizer {
    width: 38px !important;
}

.sidebar .sidebar-minimizer:hover::before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%2373818f' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E") !important;
}

.d-center {
    justify-content: center;
    align-items: center;
    display: flex;
}

.sidebar .nav-link.active {
    background-color: var(--dns-color-red-light) !important;
    color: var(--dns-color-red) !important;
    border-right: var(--dns-color-red) 5px solid !important;
    font-family: "BeVietnamPro-SemiBold", sans-serif;
}

.sidebar .nav-link.active .nav-icon {
    color: var(--dns-color-red) !important;
}

.sidebar .nav-link {
    color: var(--dns-color-blue);
    font-size: 13.5px !important;
}

.sidebar {
    background-color: var(--dns-color-white) !important;
}



.sidebar .nav-title {
    color: var(--dns-color-blue) !important;


}

.sidebar .nav-link .nav-icon {
    color: var(--dns-color-blue) !important;
    font-size: 15.5px !important;
}

.btn-danger {
    color: var(--dns-color-white);
    background-color: var(--dns-color-red) !important;
    border-color: var(--dns-color-red);
}

.btn-outline-danger {
    color: var(--dns-color-white) !important;
    border-color: var(--dns-color-red) !important;
}

.sidebar .nav-link .nav-icon.mobile_icon {
    font-size: 22.5px !important;
}

/* @media print {
    .print {
        display: block;
    }
} */

.Loading {
    position: absolute;
    width: 300px;
    height: 300px;
    background-color: #000;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 40%;
    opacity: 0.7;
    color: var(--dns-color-white);
    border-radius: 20px;
}

@media (max-width: 991.98px) {
    .app-header .navbar-brand .navbar-brand-full {
        display: none !important;
    }

    .app-header .navbar-brand .navbar-brand-minimized {
        display: block !important;
        width: 50px !important;
    }

    .app-header .navbar-brand {
        position: absolute;
        top: 0;
        left: 0px !important;
        margin-left: 0px !important;
        z-index: -1 !important;
    }


}

@media (max-width: 460px) {
    .app-header .navbar-brand img {
        width: 85% !important;
        height: auto !important;
    }

    .app-header .navbar-brand {
        top: 0 !important;
        left: 0 !important;
        transform: translate(30%, 0%) !important;
        margin-left: auto !important;
    }
}

/*New TOPBAR CSS :: START*/
.tablet_nabar {
    width: calc(100% - 250px);
    display: flex;
}

.topbar_logo {
    width: 250px;
    min-width: 250px;
    max-width: 250px;
    display: flex;
    justify-content: space-between;
}

.topbar_logo img.navbar-brand-full {
    width: 100%;
    height: auto;
    padding: 5px;
}

.tablet_nabar .navbar-nav button.image_button_header {
    min-width: 70px;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    scrollbar-color: #797979;
    scrollbar-width: auto
}

::-webkit-scrollbar {
    background-color: #d2d2d2;
    border-radius: 10px;
    height: 10px;
    width: 10px
}

::-webkit-scrollbar-thumb {
    background-color: var(--dns-color-red);
    border: none;
    border-radius: 10px
}

.tablet_nabar .navbar-nav {
    justify-content: space-between;
}

@media (max-width: 1320px) {
    .app-header .tablet_nabar .first-device-header.navbar-nav button {
        min-width: 100px;
        width: max-content;
    }

    .app-header .tablet_nabar .first-device-header.navbar-nav {
        overflow-y: hidden;
        overflow-x: scroll;
        padding: 5px 0px;
    }
}

@media (max-width: 991.98px) {
    .app-header {
        height: max-content !important;
        padding-bottom: 11px !important;
    }

    .app-body {
        margin-top: 130px !important;
    }

    .app-body.dashboard-main-wrapper {
        margin-top: 60px !important;
    }

    .main_container_wrapper_app {
        padding-top: 10px !important;
    }

    .tablet_nabar .navbar-nav {
        justify-content: start;
    }

    .tablet_nabar .navbar-nav .mx-2 {
        width: max-content !important;
    }

    .topbar_logo {
        margin-top: 15px;
    }

    .tablet_nabar .navbar-nav {
        margin-top: 15px;
        margin-right: 0px !important;
    }

    .tablet_nabar .update-btn {
        padding: 8px 10px !important;
    }

    .tablet_nabar {
        display: flex;
        flex-direction: column-reverse;
        margin-top: -50px;
        /* overflow: hidden; */
        width: 100%;
        z-index: -1;
    }

    .sidebar .nav-link {
        font-size: 15px !important;
    }
}

@media (min-width: 280px) and (max-width: 700px) {
    .app-header .navbar-brand {
        width: 100px !important;
    }

    .tablet_nabar .update-btn {
        width: max-content;
    }

    .tablet_nabar ul.navbar-nav:nth-child(1) {
        overflow-x: scroll;
    }
}

.swal2-modal .swal2-title h3 {
    font-size: 25px;
}

.swal-custome-text {
    color: black;
    font-size: 16.5px;
}

.swal-custome-text span {
    color: var(--dns-color-red);
    font-size: 19px;
}

.swal2-input {
    margin: 10px 0 5px 0 !important;
    height: 50px;
    max-height: 50px;
    min-height: 50px;
    border-radius: 5px;
    border: 1px solid #b4b4b4 !important;
    background-color: #f2f2f2;
    box-shadow: none;
    margin-bottom: 10px;
    outline: none;
    padding: 5px;
    width: 100%;
}

.swal2-actions button {
    box-shadow: none !important;
    outline: none !important;
    font-size: 15px !important;
}

.swal2-validation-message {
    font-size: 16px !important;
}

.swal2-styled.swal2-confirm {
    background-color: rgb(216, 7, 7) !important;
    border-left-color: rgb(216, 7, 7) !important;
    border-right-color: rgb(216, 7, 7) !important;
}

.swal2-container .swal2-popup #swal2-content p {
    font-size: 22.5px !important;
    margin-bottom: 15px !important;
}

.sidebar .nav,
.sidebar .sidebar-nav {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #73818f;
    pointer-events: none;
    background-color: transparent;
    cursor: no-drop;
}

@media (min-width: 992px) {
    .sidebar-fixed .sidebar {
        width: 250px;
        min-width: 250px;
        max-width: 250px;
    }

    html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main,
    html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer,
    html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main,
    html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
        margin-left: 250px;
    }

    html:not([dir="rtl"]) .sidebar {
        margin-left: -260px;
    }

    .sidebar-minimized.sidebar-fixed .sidebar {
        overflow: hidden;
    }

    .sidebar-minimized.sidebar-fixed .sidebar,
    .sidebar-minimized .sidebar .sidebar-nav {
        min-width: 50px;
        max-width: 50px;
    }
}

/* Preloader  CSS :: START*/

.de-loader.with-bg {
    position: fixed;
    z-index: 20000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.de-loader .lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.de-loader .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.de-loader .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 5px;
    height: 5px;
    border: solid 1px red;
    border-radius: 50%;
    background: none !important;
    margin: -2px 0 0 -2px;
}

.de-loader .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.de-loader .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.de-loader .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.de-loader .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.de-loader .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.de-loader .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.de-loader .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.de-loader .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.de-loader .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.de-loader .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.de-loader .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.de-loader .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.de-loader .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.de-loader .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.de-loader .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.de-loader .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


/* Preloader  CSS :: END*/

.password-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 45px;
    height: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.password-icon i {
    color: var(--thm-color-red);
    cursor: pointer;
}

.error_msg {
    color: var(--dns-color-red);
    font-size: 12.5px;
    margin-top: 5px !important;
    padding: 0;
    margin-bottom: 5px !important;
}

.dark-gray-col,
.kt_part_col,
.metklej_part_col,
.plastic_part_col,
.rf_part_col {
    fill: #d6dce4 !important;
    fill: var(--col--dark-gray) !important;
    background-color: #d6dce4 !important;
    background-color: var(--col--dark-gray) !important;
    color: var(--main-black) !important
}

.multiple_part_col {
    fill: #1cdcbc !important;
    fill: var(--col--dark-multiPart) !important
}

.light-gray-col,
.vs_part_col {
    fill: #eeecec !important;
    fill: var(--light-gray) !important;
    background-color: #eeecec !important;
    background-color: var(--light-gray) !important;
    color: var(--main-black) !important
}

.bau_part_col,
.des_part_col,
.green-col,
.lak_part_col {
    fill: #d7eaca !important;
    fill: var(--col-green) !important;
    background-color: #d7eaca !important;
    background-color: var(--col-green) !important;
    color: var(--main-black) !important
}

.blue-col,
.glue_part_col,
.i_part_col,
.preprints_part_col,
.replace_part_col,
.wr_part_col {
    fill: #c6f0ff !important;
    fill: var(--col-blue) !important;
    background-color: #c6f0ff !important;
    background-color: var(--col-blue) !important;
    color: var(--main-black) !important
}

.al_part_col,
.alu_part_col,
.orange-col,
.pp_part_col {
    fill: #fbe4d5 !important;
    fill: var(--col-orange) !important;
    background-color: #fbe4d5 !important;
    background-color: var(--col-orange) !important;
    color: var(--main-black) !important
}

.green-col,
.paint_part_col {
    fill: #d7eaca !important;
    fill: var(--col-green) !important;
    background-color: #d7eaca !important;
    background-color: var(--col-green) !important;
    color: var(--main-black) !important
}

.red-col {
    fill: #ef3d3c !important;
    fill: var(--col-red) !important;
    background-color: #ef3d3c !important;
    background-color: var(--col-red) !important;
    color: var(--main-black) !important
}

.disassemblyandassembly_part_col,
.fix_part_col,
.pd_part_col,
.yellow-col {
    background-color: #fff3cb !important;
    background-color: var(--col-yellow) !important;
    color: var(--main-black) !important
}

.disassemblyandassembly_part_col,
.theme-col {
    fill: #d80707 !important;
    fill: var(--table-header-color) !important;
    background-color: #d80707 !important;
    background-color: var(--table-header-color) !important
}

.ptp_part_col {
    fill: #eeecec !important;
    fill: var(--col-gray) !important;
    background-color: #eeecec !important;
    background-color: var(--col-gray) !important;
    color: var(--main-black) !important
}

